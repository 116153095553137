<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <h1>{{ i18nT(`Employees`) }}</h1>
                            <div class="mb-1">
                <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                  {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                  {{ i18nT(`entries`) }}</span>
                            </div>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                          <b-dropdown
                              v-if="iCan( 'employees', 'write' )"
                              id="add-new-dropdown"
                              variant="primary"
                              class="float-right"
                              right
                          >
                            <template #button-content>
                              <feather-icon icon="PlusCircleIcon" />
                              {{ i18nT(`Add new`) }}
                            </template>

                            <b-dropdown-item>
                              <b-link :to="{ name: 'create-employee' }">
                                <font-awesome-icon class="mr-25" :icon="['fas', 'user-plus']" />
                                {{ i18nT(`Add`) }}
                              </b-link>
                            </b-dropdown-item>

                            <b-dropdown-item
                                v-b-modal.modal-import-csv
                                >
                              <div class="text-primary">
                                <font-awesome-icon class="mr-25" :icon="['fas', 'file-import']" />
                                {{ i18nT(`Import`) }}
                              </div>
                            </b-dropdown-item>

                          </b-dropdown>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                            v-if="controlVisible"
                        >
                            <b-form-group :label="i18nT(`Department`)">
                                <v-select
                                    v-model="department"
                                    :options="departments"
                                    label="text"
                                    :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            v-if="controlVisible"
                        >
                            <b-form-group :label="i18nT(`Team`)">
                                <v-select
                                    v-model="group"
                                    :options="groups"
                                    label="text"
                                    :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0 && iCan('employees', 'write')"
                            >
                                <b-dropdown-item @click="onBulkDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{
                                  i18nT(`Export`)
                                }}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`Excel`) }}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`CSV`) }}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        :key="'EmployeeTable'+tableColumns.length"
                        ref="refDocumentListTable"
                        :items="fetchDocuments"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >
                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                class="pl-0"
                                v-model="selectedItems"
                                :value="data.item.user.Id"
                            />
                        </template>

                        <template #cell(name)="data">
                            <div class="media">
                                <div class="media-aside align-self-center">
                                    <b-avatar
                                        :to="{
                                          name: 'view-employee',
                                          params: { id: data.item.user.Id },
                                        }"
                                        v-b-tooltip.hover="formattedOffboardingDate(data.item)"
                                        :src="data.item.user.ImageUrl"
                                        :text="(data.item.user.FirstName ? data.item.user.FirstName.charAt(0) : '') +
                                               (data.item.user.LastName ? data.item.user.LastName.charAt(0) : '')"
                                        :badge="data.item.application.OffboardingAt ? true : false"

                                        badge-variant="primary"
                                        class="badge-light-primary"

                                    >
                                    </b-avatar>
                                </div>
                                <div class="media-body">
                                    <b-link
                                        :to="{name: 'view-employee', params: { id: data.item.user.Id }}"
                                        class="font-weight-bold d-block text-nowrap"
                                    >
                                        {{ data.item.user.Label }}
                                    </b-link>
                                    <p class="mb-0">{{ data.item.user.Position }}</p>
                                </div>
                            </div>
                            <!--<b-link :to="{ name: 'view-employee', params: { id: data.item.user.Id }}" class="d-flex align-items-center font-weight-bold">
                                  <b-avatar
                                    :src="data.item.user.ImageUrl"
                                    :text="(data.item.user.FirstName ? data.item.user.FirstName.charAt(0) : '') + (data.item.user.LastName ? data.item.user.LastName.charAt(0) : '')"
                                    class="mr-50 badge-light-primary"
                                  >
                                  </b-avatar>
                                  {{ data.item.user.Label }}
                              </b-link>-->
                        </template>

                        <template #cell(department)="data">
                            <div v-if="data.item.departments.length > 0">
                                <div
                                    v-for="(label, index) in data.item.departments.split(',')"
                                    :key="index"
                                    class="
                    badge
                    text-capitalize
                    badge-light-primary badge-pill
                    mr-25
                  "
                                >
                                    {{ label }}
                                </div>
                            </div>
                        </template>

                        <template #cell(email)="data">
                            <span>{{ data.item.user.Email  }}</span>
                        </template>

                        <template #cell(phone)="data">
                            <span>{{ data.item.user.Phone ? data.item.user.Phone : "N/A" }}</span>
                        </template>

                        <template #cell(status)="data">
                            
                            <span class="badge badge-light-success badge-pill"
                                v-if="data.item.user.PublicationStatus == 'ACTIVE'">
                                {{ i18nT(`Connected`) }}
                            </span>
                            <span class="badge badge-light-secondary badge-pill"
                                v-if="data.item.user.PublicationStatus !== 'ACTIVE' && !data.item.user.Invited">
                                {{ i18nT(`Not connected`) }}
                            </span>
                            <span
                                class="badge badge-light-info badge-pill"
                                v-if="data.item.user.PublicationStatus !== 'ACTIVE' && data.item.user.Invited">
                                {{ i18nT(`Invited`) }}
                            </span>
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                v-if="iCan('employees', 'write')"
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >
                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item :to="{
                                        name: 'edit-employee',
                                        params: { id: data.item.user.Id },
                                      }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item :to="{
                                        name: 'view-employee',
                                        params: { id: data.item.user.Id },
                                      }">
                                    <feather-icon icon="EyeIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`View`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="data.item.user.PublicationStatus !== 'ACTIVE' && !data.item.user.Invited" @click="sendInvite(data.item.user.Id)" >
                                    <feather-icon icon="UserPlusIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Invite`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="data.item.user.PublicationStatus !== 'ACTIVE' && data.item.user.Invited" @click="sendInvite(data.item.user.Id)" >
                                    <feather-icon icon="UserPlusIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Resend Invite`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item :to="{
                                        name: 'inbox-send',
                                        params: { id: data.item.user.Id, software: software },
                                      }">
                                    <feather-icon icon="SendIcon"/>
                                    <span class="align-middle ml-50">{{
                                            i18nT(`Send message`)
                                        }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="moveStage(data)"
                                                 v-if="iCan('onboarding', 'write') && !data.item.application.OffboardingAt"
                                >
                                    <feather-icon icon="LogOutIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Offboard`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="onDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                </div>
                <!-- Disable grid view -->
                <b-tabs
                    pills
                    align="center"
                    v-if="false"
                >
                    <b-tab active>
                        <template #title>
                            <feather-icon
                                icon="ListIcon"
                                size="24"
                                style="margin: 0"
                            />
                        </template>
                    </b-tab>
                    <b-tab>
                        <template
                            #title
                            style="text-align: center"
                        >
                            <feather-icon
                                icon="GridIcon"
                                size="24"
                                style="margin: 0"
                            />
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    sm="12"
                                    md="6"
                                    xl="4"
                                    v-for="document in employees"
                                    :key="document.Id"
                                >
                                    <b-card
                                        no-body
                                        style=" border: 1px solid rgba(0, 0, 0, 0.07);
                      margin-bottom: 20px;
                      height: calc(100% - 20px);
                    "
                                    >
                                        <b-card-header>
                                            <div class="d-flex w-100">
                                                <b-avatar
                                                    :src="document.user.ImageUrl"
                                                    :text="(document.user.FirstName ? document.user.FirstName.charAt(0) : '') + (document.user.LastName ? document.user.LastName.charAt(0) : '')"
                                                    class="badge-light-primary mr-1"
                                                >
                                                </b-avatar>
                                                <div class="flex-grow-1">
                                                    <b-link :to="{
                                                          name: 'view-employee',
                                                          params: { id: document.user.Id },
                                                        }">
                                                        <h4 class="mb-0">{{ document.user.Label }}</h4>
                                                    </b-link>
                                                    <p class="mb-0">{{ document.user.Position }}</p>
                                                    <small class="text-muted">{{ i18nT(`Started`) }} {{document.user.CreatedAt | date }}{{
                                                            document.user.Experience ? ", " +
                                                                document.user.Experience +
                                                                (document.user.Experience > 1
                                                                    ? " years"
                                                                    : " year")
                                                                : ""
                                                        }}</small>
                                                </div>
                                                <div>
                                                    <b-badge
                                                        pill
                                                        variant="light-success"
                                                        class="mt-1"
                                                    >{{ i18nT(`Active`) }}
                                                    </b-badge>
                                                </div>
                                            </div>
                                            <div class="d-flex w-100 mt-1 ml-4">
                                                <b-link href="#" class="position-relative">
                                                    <feather-icon variant="primary" icon="MailIcon" size="21" class="mr-2"/>
                                                    <b-badge
                                                        pill
                                                        variant="danger"
                                                        class="position-absolute"
                                                        style="top: -10px; right: 7px"
                                                    >{{ i18nT(`6`) }}
                                                    </b-badge>
                                                </b-link>
                                                <b-link href="#" class="position-relative" >
                                                    <feather-icon
                                                        variant="primary"
                                                        icon="CalendarIcon"
                                                        size="21"
                                                        class="mr-2"
                                                    />
                                                    <b-badge
                                                        pill
                                                        variant="danger"
                                                        class="position-absolute"
                                                        style="top: -10px; right: 7px"
                                                    >{{ i18nT(`6`) }}
                                                    </b-badge>
                                                </b-link>
                                                <b-link
                                                    href="#"
                                                    class="position-relative disabled"
                                                >
                                                    <feather-icon
                                                        icon="ClockIcon"
                                                        size="21"
                                                        class="mr-2"
                                                    />
                                                    <!-- <b-badge pill variant="danger" class="position-absolute" style="top: -10px; right: 7px;">{{i18nT(`6`)}}</b-badge> -->
                                                </b-link>
                                                <b-link
                                                    href="#"
                                                    class="position-relative disabled"
                                                >
                                                    <feather-icon
                                                        icon="CheckSquareIcon"
                                                        size="21"
                                                        class="mr-2"
                                                    />
                                                    <!-- <b-badge pill variant="danger" class="position-absolute" style="top: -10px; right: 7px;">{{i18nT(`6`)}}</b-badge> -->
                                                </b-link>
                                                <b-link
                                                    href="#"
                                                    class="position-relative disabled"
                                                >
                                                    <feather-icon
                                                        icon="InboxIcon"
                                                        size="21"
                                                        class="mr-2"
                                                    />
                                                    <!-- <b-badge pill variant="danger" class="position-absolute" style="top: -10px; right: 7px;">{{i18nT(`6`)}}</b-badge> -->
                                                </b-link>
                                            </div>
                                        </b-card-header>
                                        <b-card-body>
                                            <div>
                                                <h6 class="section-label">{{ i18nT(`Time off`) }}</h6>
                                                <div class="d-flex mt-50">
                                                    <div
                                                        class="bg-light text-center mr-1 p-1 rounded"
                                                        style="width: 100px"
                                                    >
                                                        <p>{{ i18nT(`Available`) }}</p>
                                                        <p
                                                            class="text-primary mb-0 font-weight-bolder"
                                                            style="font-size: 20px"
                                                        >
                                                            20
                                                        </p>
                                                    </div>
                                                    <div
                                                        class="bg-light text-center p-1 rounded"
                                                        style="width: 100px"
                                                    >
                                                        <p>{{ i18nT(`Taken`) }}</p>
                                                        <p
                                                            class="mb-0 font-weight-bolder"
                                                            style="font-size: 20px"
                                                        >
                                                            7
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <h6 class="section-label">{{ i18nT(`LABELS`) }}</h6>
                                                <div class="mt-50">
                                                    <div class="tag-container">{{ i18nT(`Innovative`) }}</div>
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <h6 class="section-label">{{ i18nT(`Skills`) }}</h6>
                                                <div class="mt-50">
                                                    <div class="tag-container">{{ i18nT(`Innovative`) }}</div>
                                                </div>
                                            </div>
                                        </b-card-body>
                                        <b-card-footer>
                                            <b-dropdown class="float-right"
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                                right
                                            >
                                                <template #button-content>
                                                    <feather-icon
                                                        icon="MoreVerticalIcon"
                                                        size="16"
                                                        class="align-middle"
                                                    />
                                                </template>

                                                <b-dropdown-item :to="{name: 'edit-document', params: { id: document.Id }}">
                                                    <feather-icon icon="EditIcon"/>
                                                    <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                                                </b-dropdown-item>

                                                <b-dropdown-item :to="{ name: 'view-employee', params: { id: document.user.Id },}">
                                                    <feather-icon icon="EyeIcon"/>
                                                    <span class="align-middle ml-50">{{i18nT(`View`)}}</span>
                                                </b-dropdown-item>

                                                <b-dropdown-item @click="onDelete({ item: document })">
                                                    <feather-icon icon="TrashIcon" class="text-danger"/>
                                                    <span class="align-middle ml-50 text-danger">{{i18nT(`Delete`) }}</span>
                                                </b-dropdown-item>

                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col cols="12" sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }} {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>

        <!-- MODAL EDIT STAGE (PIPELINE) -->
        <b-modal
            id="modal-pipeline"
            :title="i18nT(`New / edit stage`)"
            ok-only
            ok-title="Save"
            no-close-on-backdrop
        >
            <span>{{ i18nT(`Create / edit stages in your pipeline.`) }}</span>
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Stage Name`)"
                                label-for="pipelineName"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Stage Name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="pipelineName"
                                        :placeholder="i18nT(`E.g. interview template`)"
                                        name="subject"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Stage Type`)"
                                label-for="stageType"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Stage Type"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="stageType"
                                        label="title"
                                        :options="stageTypes"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL OFFBOARD -->
        <modal-offboard
            :employee="modalUser"
            :ext-departure-date="departureDate"
            :ext-notice-date="noticeDate"
            :can-select-employee="false"
            @close-modal="onOffboarded"
            ref="modal-change-status"></modal-offboard>

        <!-- MODAL IMPORT CSV -->
        <modal-import-csv class-name="employees" @imported="refetchData"/>
    </div>

</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BAvatar,
    VBTooltip,
    VBModal,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    // BListGroup, BListGroupItem,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    BFormSelect,
    BLink,
    BFormInput,
    BCardHeader,
    BBadge
    // VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useEmployeeList from './useEmployeeList'
import {avatarText} from '@core/utils/filter'
import {
    dictToSelectArrayWithTitle,
    dictToSelectArray
} from '@core/utils/utils'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import useAuth from '@/auth/useAuth'
import ModalOffboard from "@/views/onboarding/offboarding/ModalOffboard.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalImportCsv from "@/views/common/ModalImportCsv/ModalImportCsv.vue";


// import _ from 'lodash'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        BAvatar,
        // BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        // BListGroup,
        // BListGroupItem,
        BCardFooter,
        BCardBody,
        vSelect,
        BFormCheckbox,
        BFormSelect,
        BLink,
        BFormInput,
        BCardHeader,
        BBadge,
        ModalOffboard,
        ModalImportCsv,
        ToastificationContent
        // VBModal
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            userId: useAuth.getProperUserId(),
            modalUser: {},
            noticeDate: '',
            departureDate: '',
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            departments: [],
            groups: [],
            isActionsShow: false,
            software: useAuth.getCurrentSoftware(),
            controlVisible: true,
            email: false,
            avalableFields: [
                {label: this.i18nT(`Department`), key: 'department', visible: true}, 
                {label: this.i18nT(`Email`), key: 'email', visible: true}, 
                {label: this.i18nT(`Phone`), key: 'phone', visible: true}, 
            ],
        }
    },
    created() {               
        this.departments.unshift({
            value: '',
            text: this.i18nT(`All`)
        })

        let storePreferences = useAuth.getColumnPreferences('employees')
        if(storePreferences) {
            this.avalableFields = storePreferences
        }


        this.$http.get(`system/departments`).then(({data}) => {
            this.departments.push(...dictToSelectArray(data.data))
        })

        this.groups.unshift({
            value: '',
            text: this.i18nT(`All`)
        })
        this.$http.get(`employees/employeeGroups`).then(({data}) => {
            console.log("data: ",data)
            this.groups.push(
                ...data.data.employee_groups.map(group => ({
                    value: group.group.Id,
                    text: group.group.Label
                }))
            )
        })

        this.fetchDocuments(null, this.determineVisibility);
    }, 
    methods: {
        determineVisibility(invoiceArray) {
            if(invoiceArray.length===0){
              this.controlVisible = false;
            }
        },
        sendInvite(id) {
            this.$http
                .post('employees/sendInvite?user_id=' + id)
                .then(({data}) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(data),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.refetchData()
                }).catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(error),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        formattedOffboardingDate(item) {
            if (item.application && item.application.OffboardingAt) {
                const formattedDate = this.$options.filters.date(item.application.OffboardingAt)
                return this.i18nT(`Offboarding on`) + ': ' + formattedDate
            } else {
                return '';
            }
        },
        onOffboarded() {
            this.$bvModal.hide('modal-change-status')
            this.refetchData()
        },
        moveStage(data) {
            this.selectedItems = data.item.application.Id;
            this.modalUser = data.item;
            this.noticeDate = data.item.application.OffboardingNotice;
            this.departureDate = data.item.application.OffboardingAt;
            this.statusChangeReason = data.item.application.OffboardingReason;
            this.statusChangeComment = data.item.application.OffboardingComment;
            this.$bvModal.show('modal-change-status')
            this.changeStatusNotification = false
            this.canSelectEmployee = false
        },
        removeLabel(label) {
            console.log(label)
        },
        getLabels(labelArray) {
            return dictToSelectArrayWithTitle(labelArray)
        },
        getTypeName(typeId) {
            const document = this.documentTypes.find(type => type.value == typeId)
            return document ? document.text : ''
        },

        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onDelete(employee) {
            console.log(employee)
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`employees?id=${employee.item.user.Id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The employee has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`employees?id=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`The task has been deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.user.Id)
            } else {
                this.selectedItems = []
            }
        },
        onPutInFolder(data) {
            console.log(data)
            // this.$bvModal.show('modal-pipeline');
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    setup() {
        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            department,
            group,
            employees,
            refetchData
        } = useEmployeeList(router.currentRoute.meta.template === 1)

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            department,
            group,
            avatarText,
            employees,
            refetchData
        }
    },
    computed: {
        computedColumns() {

            // let filters = [
            //     {resolution: 1500, columns: ['phone']}, 
            //     {resolution: 1300, columns: ['phone', 'email']}, 
            //     {resolution: 1000, columns: ['phone', 'email', 'department']}
            // ]
            // return this.responsiveColumns(this.tableColumns, filters)

            let col = [...this.tableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
                if(!this.avalableFields[i].visible) {
                    col = col.filter(c => c.key !== this.avalableFields[i].key)
                }
            }
              useAuth.setColumnPreferences('employees', this.avalableFields);

            return col
        },
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your employees. No employees have been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/"+ this.software +"/create-employee\"'>" + this.i18nT(`Create employee`) + "</button>"
        },
    }
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }
}

.disabled {
    color: #6c757d;
}
</style>
